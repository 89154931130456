import React from 'react';
import styled from 'styled-components';
import { MainSection } from '@components/Main/MainSection';

import BannerModel from '@models/banner';
import SectionModel from '@models/section';
import MenuModel from '@models/menu';

interface TabContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  tabs: SectionModel[];
  centerBanners: BannerModel[];
  category: MenuModel;
  isHome: boolean;
  language: string;
}

const Tab = ({ tabs, centerBanners, category, isHome, language, ...containerProps }: TabContainerProps) => {
  return (
    <Container {...containerProps}>
      <MainSection sections={tabs} centerBanners={centerBanners} category={category} isHome={isHome} language={language} />
    </Container>
  );
};

export { Tab };

const Container = styled.div`
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
`;

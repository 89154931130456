import React from 'react';
import styled from 'styled-components';
import SectionModel from '@models/section';
import ProjectModel from '@models/project';
import { SECTION_DISPLAY_TABLE, SECTION_DISPLAY_TYPE } from '@constants/constant';
import { CardList } from '@components/CardList';

interface MainSectionContentProps {
  section?: SectionModel;
}

const MainSectionContent = (props: MainSectionContentProps) => {
  const sectionTitle: string = props.section?.name ?? '';
  const projects: ProjectModel[] = props.section?.projects ?? [];
  const displayType: SECTION_DISPLAY_TYPE = (props.section?.displayType as SECTION_DISPLAY_TYPE) ?? SECTION_DISPLAY_TABLE.VERTICAL_LIST;

  return (
    <div className="section-content">
      <SectionTitleStyle>{sectionTitle}</SectionTitleStyle>
      <CardList projects={projects} displayType={displayType} />
    </div>
  );
};

export { MainSectionContent };

const SectionTitleStyle = styled.h1`
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0px 16px;
`;

import React, { useEffect } from 'react';
import TabModel from '@models/tab';
import { atom, useAtom } from 'jotai';
import BannerModel from '@models/banner';

import { Tab } from './Tab';
import SectionModel from '@models/section';
import MenuModel from '@models/menu';
import { GOOGLE_ACTION, GOOGLE_CATEGORY } from '@constants/ga';
import { trackEvent } from '@utils/ga';

interface TabListProps extends React.PropsWithChildren {
  tabs: SectionModel[];
  tabsMenu: TabModel[];
  centerBanners: BannerModel[];
  category: MenuModel;
  language: string;
}

const tabIndexAtom = atom(0);
const TabList = (props: TabListProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useAtom(tabIndexAtom);
  const tabListData = props.tabs[selectedTabIndex];
  let isHome = selectedTabIndex === 0;

  let tabMenuValue = props.tabsMenu.map((item) => item.value);
  let tabMenuName = tabMenuValue[selectedTabIndex];

  useEffect(() => {
    trackEvent(GOOGLE_CATEGORY.EVENT, GOOGLE_ACTION.SCREEN_VIEW_ACTION[tabMenuName], {
      app_name: 'realworld',
      screen_name: tabMenuName,
    });
  }, [tabMenuName]);

  const tabComponentList = props.tabsMenu.map((tab, index) => {
    return (
      <Tab
        key={tab.name}
        tabs={tabListData}
        centerBanners={props.centerBanners}
        category={props.category}
        isHome={isHome}
        style={{ display: index !== selectedTabIndex ? 'none' : '' }}
        language={props.language}
      />
    );
  });

  return (
    <div className="tabs">
      <ul className="tabs-ul">
        {props.tabs &&
          props.tabsMenu.map((tab, index) => {
            return (
              <li
                onClick={() => setSelectedTabIndex(index)}
                className={index === selectedTabIndex ? 'clicked btn' : 'btn'}
                key={tab.value}
                value={tab.value}
              >
                {tab.name}
              </li>
            );
          })}
      </ul>
      {tabComponentList}
    </div>
  );
};

export { TabList };

import React from 'react';
import Banner from '@models/banner';

interface BannerSwiperSliderProps {
  banner: Banner;
}

const BannerSwiperSlide = (props: BannerSwiperSliderProps) => {
  return (
    <a className="banner-swiper-slide-content" key={props.banner.name} href={props.banner.linkUrl} target="_blank">
      <div className="banner-swiper-slide-content-img">
        <div className="banner-swiper-slide-content-img-thumbnail" style={{ backgroundImage: `url(${props.banner.imageUrl})` }} />
      </div>
    </a>
  );
};

export { BannerSwiperSlide };

import React from 'react';
import styled from 'styled-components';
import Banner from '@models/banner';
import { Autoplay, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BannerSwiperSlide } from '@components/Swiper/BannerSwiperSlide';
import { atom, useAtom } from 'jotai';

interface BannerSwiperProps {
  banners: Banner[];
}
interface BannerSwiperIndicatorProps {
  banners: Banner[];
  currentIndex: number;
}

const BannerSwiperIndicator = (props: BannerSwiperIndicatorProps) => {
  return (
    <>
      {props.banners && (
        <div className={`banner-swiper-indicator${props.banners.length === 1 ? '-one' : ''}`}>
          <span className="banner-swiper-indicator-number current">{props.currentIndex}</span>
          <span className="banner-swiper-indicator-number">&nbsp;/&nbsp;{props.banners.length}</span>
        </div>
      )}
    </>
  );
};

const indexAtom = atom(1);
const BannerSwiper = (props: BannerSwiperProps) => {
  const [currentIndex, setCurrentIndex] = useAtom(indexAtom);

  return (
    <BannerSwiperStyle>
      {props.banners && (
        <Swiper
          className="banner-swiper-area"
          modules={[Autoplay, Parallax]}
          spaceBetween={-32}
          speed={300}
          loop={true}
          draggable={true}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          loopAdditionalSlides={1}
          parallax={true}
          onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex + 1)}
        >
          {props.banners.map((banner) => {
            return (
              <SwiperSlide key={banner.name} className="banner-swiper-slide">
                <BannerSwiperSlide banner={banner} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      {props.banners.length > 0 && <BannerSwiperIndicator banners={props.banners} currentIndex={currentIndex} />}
    </BannerSwiperStyle>
  );
};

export { BannerSwiper };

const BannerSwiperStyle = styled.div`
  position: relative;
`;

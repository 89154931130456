import React, { useEffect } from 'react';
import { MiniBanner } from '@components/MiniBanner';
import { BannerSwiper } from '@components/Swiper/BannerSwiper';
import { MainGenre } from '@components/Main/MainGenre';
import { MainSectionContent } from '@components/Main/MainSectionContent';
import SectionModel from '@models/section';
import BannerModel from '@models/banner';
import MenuModel from '@models/menu';

interface SectionProps {
  sections?: { [key: number]: SectionModel };
  centerBanners?: BannerModel[];
  category: MenuModel;
  isHome: boolean;
  language: string;
}

const MainSection = ({ sections, centerBanners, category, isHome, language }: SectionProps) => {
  const section = Object.values(sections as SectionModel[]);

  return (
    <>
      {isHome && language === 'ko' ? <MainGenre category={category} /> : <></>}
      {isHome && centerBanners ? <BannerSwiper banners={centerBanners} /> : <></>}

      <div className="section">
        {section && section.length ? (
          section.map((sc, idx) => {
            const isBanner = sc.imageUrl;
            if (isBanner) return <MiniBanner section={sc} key={idx} idx={idx} />;
            else return <MainSectionContent section={sc} key={idx} />;
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export { MainSection };

import React, { useMemo } from 'react';
import styled from 'styled-components';
import Project from '@models/project';
import { Autoplay, EffectCoverflow, Mousewheel, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HeaderSwiperSlide } from '@components/Swiper/HeaderSwiperSlide';
import { atom, useAtom } from 'jotai';

const coverflowSetting = {
  slideShadows: false,
  rotate: 0,
  stretch: 0,
  depth: 100,
};

const mouseWheelSetting = {
  releaseOnEdges: true,
  forceToAxis: true,
};

const autoPlaySetting = {
  delay: 5000,
  disableOnInteraction: false,
};
interface HeaderSwiperProps {
  projects?: Project[];
}

const indexAtom = atom(0);
const HeaderSwiper = (props: HeaderSwiperProps) => {
  const [currentIndex, setCurrentIndex] = useAtom(indexAtom);
  const currentImageUrl = useMemo(() => {
    if (props.projects?.length) {
      return props.projects[currentIndex].verticalImageUrl ? props.projects[currentIndex].verticalImageUrl : props.projects[currentIndex].imageUrl;
    }
  }, [props.projects, currentIndex]);

  return (
    <div className="header-swiper">
      <HeaderSwiperStyle projectLength={props.projects?.length ? props.projects?.length : 0}>
        {props.projects && (
          <Swiper
            className="header-swiper-area"
            modules={[Autoplay, EffectCoverflow, Mousewheel, Parallax]}
            centeredSlides={true}
            speed={700}
            slidesPerView={'auto'}
            loop={true}
            draggable={true}
            grabCursor={true}
            parallax={true}
            effect={'coverflow'}
            coverflowEffect={coverflowSetting}
            mousewheel={mouseWheelSetting}
            slideToClickedSlide={true}
            autoplay={autoPlaySetting}
            roundLengths={true}
            onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
          >
            {props.projects.map((project, index) => {
              return (
                <SwiperSlide key={project.id} className="header-swiper-slide">
                  <HeaderSwiperSlide project={project} index={index} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </HeaderSwiperStyle>

      <div className="header-swiper-background-img">
        <BackgroundImg src={currentImageUrl} />
      </div>
    </div>
  );
};

const HeaderSwiperStyle = styled.div<{ projectLength: number }>`
  position: relative;
  height: 100%;
  max-width: ${(props) => (props.projectLength <= 4 ? '' : '1320px')};
  margin: 0 auto;
  @media (max-width: 519px) {
    max-width: ${(props) => (props.projectLength <= 4 ? '' : '1320px')};
  }
`;
const BackgroundImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

export { HeaderSwiper };

import React, { useLayoutEffect, useRef, useEffect } from 'react';
import HeaderContainer from '@containers/HeaderContainer';
import BodyContainer from '@containers/BodyContainer';
import FooterContainer from '@containers/FooterContainer';
import PageLayout, { ContentFill } from '@layouts/PageLayout';
import { FloatingUpButton } from '@components/FloatingUpButton';

import { PageProps, navigate } from 'gatsby';

import SectionModel from '@models/section';
import MenuModel from '@models/menu';
import ProjectModel from '@models/project';
import BannerModel from '@models/banner';
import { useTranslation } from 'react-i18next';
import i18n from '@locales/i18n';

interface IndexProps {
  pageContext: ContextProps;
}

interface ContextProps {
  sections: SectionModel[];
  category: MenuModel;
  headerBanners: ProjectModel[];
  centerBanners: BannerModel[];
  tabs: SectionModel[];
  language: string;
}

interface ContainerProps {
  category: MenuModel;
  headerBanners: ProjectModel[];
  tabs: SectionModel[];
  centerBanners: BannerModel[];
  language: string;
}

const SuccessContainers = ({ category, headerBanners, tabs, centerBanners, language }: ContainerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <ContentFill ref={ref}>
      <HeaderContainer headerBanners={headerBanners} />
      <BodyContainer category={category} tabs={tabs} centerBanners={centerBanners} language={language} />
      <FooterContainer />
      <FloatingUpButton scrollContainerRef={ref} />
    </ContentFill>
  );
};

const IndexPage = ({ pageContext }: IndexProps) => {
  const { sections, category, headerBanners, centerBanners, tabs, language } = pageContext;
  const isClientSide = typeof window !== 'undefined';

  i18n.changeLanguage(language);

  return (
    <PageLayout>
      <SuccessContainers headerBanners={headerBanners} category={category} tabs={tabs} centerBanners={centerBanners} language={language} />
    </PageLayout>
  );
};

export default IndexPage;

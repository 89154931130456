import React from 'react';
import { useTranslation } from 'react-i18next';
import LockIcon from '../../assets/images/46_lock_icon.svg';
import Project from '@models/project';
import useModal from '@hooks/useModal';
import { filterGameType, setMobileSchema } from '@utils/project';
import { MODAL_TYPES } from '@components/Modal/GlobalModal';
import { MOBILE_SCHEMA_TABLE } from '@constants/constant';
import useLockModal from '@hooks/useLockModal';

interface SwiperSlideContentProps {
  // setModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
  project: Project;
  index: number;
}

const SlideLabel = (project: Project) => {
  const { t } = useTranslation();
  return project.price ? (
    <div className="header-swiper-slide-label-text">{filterGameType(project)}</div>
  ) : (
    <div className="header-swiper-slide-label-text">
      {t('Free')}ㆍ{filterGameType(project)}
    </div>
  );
};

// const SlideBadge = (project: Project) => {
//   return (
//     <span className="header-swiper-slide-content-wrap-badges">
//       {project.badges.map((badge) => {
//         return (
//           <span key={badge} className="header-swiper-slide-content-wrap-badges-content">
//             <span className={'header-swiper-slide-content-wrap-badges-content' + '-' + badge}>{badge.toUpperCase()}</span>
//           </span>
//         );
//       })}
//     </span>
//   );
// };

const SlideBadge = (project: Project) => {
  return (
    <span className="header-swiper-slide-badge-item">
      {project.badges.map((badge) => {
        return (
          <span key={badge} className="header-swiper-slide-badge-item-content">
            <span className={'header-swiper-slide-badge-item-content' + '-' + badge}>{badge.toUpperCase()}</span>
          </span>
        );
      })}
    </span>
  );
};

const HeaderSwiperSlide = (props: SwiperSlideContentProps) => {
  const { showLockModal, hideLockModal } = useLockModal();

  const checkProjectLock = (project: Project, event: React.MouseEvent<HTMLElement>) => {
    if (project.isLockedDefault) {
      event.preventDefault();
      showLockModal();
    } else hideLockModal();
  };

  return (
    <a href={setMobileSchema(props.project.id, MOBILE_SCHEMA_TABLE.FREE)} key={props.project.id} onClick={(e) => checkProjectLock(props.project, e)}>
      <div className="header-swiper-slide-label">{SlideLabel(props.project)}</div>
      <div className="header-swiper-slide-img">
        <img className="fit-cover" src={props.project.verticalImageUrl || props.project.imageUrl} />
        {props.project.isLockedDefault && (
          <div className="header-swiper-slide-img-lock">
            <img src={LockIcon.toString()} />
          </div>
        )}
        <div className="header-swiper-slide-badge">{props.project.badges && SlideBadge(props.project)}</div>
      </div>

      {/* <div className="header-swiper-slide-content">
        <span className="header-swiper-slide-content-description-wrap">
          <span className="header-swiper-slide-content-wrap-title">
            <span className="text-ellipsis title-margin">
              {props.project.badges && SlideBadge(props.project)}
              {props.project.name}
            </span>
          </span>
        </span>
        <span className="header-swiper-slide-content-description text-ellipsis">{props.project.description}</span>
      </div> */}
    </a>
  );
};

export { HeaderSwiperSlide };

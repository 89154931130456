import React from 'react';
import { HeaderSwiper } from '@components/Swiper/HeaderSwiper';
import ProjectModel from '@models/project';

interface Project {
  headerBanners: ProjectModel[];
}

const HeaderContainer = ({ headerBanners }: Project) => {
  return <HeaderSwiper projects={headerBanners} />;
};

export default HeaderContainer;

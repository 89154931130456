import SectionModel from '@models/section';
import { trackEvent } from '@utils/ga';
import React from 'react';

interface MiniBannerProps {
  section: SectionModel;
  idx: number;
}

const bannerClick = (sectionName: string, idx: number) => {
  trackEvent('event', 'click_miniBanner', {
    miniBanner_idx: idx,
    miniBanner_name: sectionName,
  });
};

const MiniBanner = (props: MiniBannerProps) => {
  return (
    <a href={`${props.section.linkUrl}`} className="mini-banner" onClick={() => bannerClick(props.section.name, props.idx)}>
      <img className="mini-banner-img" src={props.section.imageUrl} />
    </a>
  );
};

export { MiniBanner };

import useSWR from 'swr';
import UserModel from '@models/user';
import BannerModel from '@models/banner';
import SectionModel from '@models/section';
import ProjectModel from '@models/project';
import MenuModel from '@models/menu';
import { fetcher } from '@api/index';

const useApi = () => {
  const accessToken = typeof window !== `undefined` && window.localStorage.getItem('Realworld.accessToken');
  const { data: userInfo, error: userInfoError } = useSWR<UserModel>(accessToken ? [`/api/me`, accessToken] : null, fetcher);

  return {
    userInfo: userInfo,
    userInfoError: userInfoError,
    isUserInfoLoading: !userInfoError && !userInfo,
  };
};

export default useApi;

import React from 'react';
import newIcon from '../../assets/images/new.svg';
import popularIcon from '../../assets/images/popular.svg';
import snackIcon from '../../assets/images/snack.svg';
import multiIcon from '../../assets/images/multi.svg';
import comicIcon from '../../assets/images/comic.svg';
import dramaIcon from '../../assets/images/drama.svg';
import historyIcon from '../../assets/images/history.svg';
import educationIcon from '../../assets/images/education.svg';
import detectiveIcon from '../../assets/images/detective.svg';
import fantasyIcon from '../../assets/images/fantasy.svg';
import sfIcon from '../../assets/images/sf.svg';
import horrorIcon from '../../assets/images/horror.svg';
import { useTranslation } from 'react-i18next';
import useApi from '@hooks/useApi';
import { trackEvent } from '@utils/ga';
import { GOOGLE_ACTION, GOOGLE_CATEGORY } from '@constants/ga';
//
import MenuModel from '@models/menu';

interface GenreContentType {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface LocationState {
  previousPathname: string;
}

type genreType = {
  [index: string]: GenreContentType;
  new: GenreContentType;
  popular: GenreContentType;
  snack: GenreContentType;
  multiPlay: GenreContentType;
  comic: GenreContentType;
  drama: GenreContentType;
  historic: GenreContentType;
  educational: GenreContentType;
  detective: GenreContentType;
  fantasy: GenreContentType;
  'sci-fi': GenreContentType;
  horror: GenreContentType;
};

const genreContent: genreType = {
  new: { icon: newIcon },
  popular: { icon: popularIcon },
  snack: { icon: snackIcon },
  multiPlay: { icon: multiIcon },
  comic: { icon: comicIcon },
  drama: { icon: dramaIcon },
  historic: { icon: historyIcon },
  educational: { icon: educationIcon },
  detective: { icon: detectiveIcon },
  fantasy: { icon: fantasyIcon },
  'sci-fi': { icon: sfIcon },
  horror: { icon: horrorIcon },
};

interface MainGenreProps {
  category: MenuModel;
}

const MainGenre = ({ category }: MainGenreProps) => {
  const { t } = useTranslation();
  const { userInfo } = useApi();
  const genres = category ? category.genres : [];
  const userName = userInfo ? userInfo.name : t('Player');

  return (
    <>
      <div className="home-title">
        <div className="home-title-player">
          <p className="home-title-player-name">{userName}</p>
          <div className="home-title-player-name-desc">
            <p>{t('Hello')}</p>
          </div>
        </div>
        <p className="home-title-desc">{t('Choice')}</p>
      </div>
      <div className="home-content">
        <div className="home-content-genre">
          {genres.map((genre) => {
            return (
              <div key={genre.name} className="home-content-genre-wrap">
                <a
                  className="genre-item"
                  href={`realworld://webapp?id=genre&query=${encodeURIComponent(`genre=${genre.value}&isRoute=false`)}`}
                  onClick={() => trackEvent(GOOGLE_CATEGORY.EVENT, GOOGLE_ACTION.CLICK_ACTION.GENRE[genre.value])}
                >
                  <div className="genre-item-img">
                    <img className="genre-item-img-100" src={genreContent[genre.value].icon.toString()} alt={t(genre.value)} />
                  </div>
                  <div className="genre-item-name">{t(genre.value)}</div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { MainGenre };

type GenreClickActionType = {
  [key: string]: string;
  new: string;
  popular: string;
  snack: string;
  multiPlay: string;
  comic: string;
  drama: string;
  historic: string;
  educational: string;
  detective: string;
  fantasy: string;
  'sci-fi': string;
  horror: string;
};

type BannerClickActionType = {
  [key: string]: string;
  top: string;
  center: string;
  mini: string;
};

type ScrollActionType = {
  [key: string]: string;
  list: string;
};

type ScreenViewActionType = {
  [key: string]: string;
  recommended: string;
  creator: string;
  outDoor: string;
  free: string;
};

type ClickActionType = {
  GENRE: GenreClickActionType;
  BANNER: BannerClickActionType;
};

type GoogleActionType = {
  SCREEN_VIEW_ACTION: ScreenViewActionType;
  CLICK_ACTION: ClickActionType;
  SCROLL_ACTION: ScrollActionType;
};

const GOOGLE_ACTION: GoogleActionType = {
  SCREEN_VIEW_ACTION: {
    recommended: 'screen_recommended_view',
    creator: 'screen_creator_view',
    outDoor: 'screen_outDoor_view',
    free: 'screen_free_view',
  },
  CLICK_ACTION: {
    BANNER: {
      top: 'click_mainContents',
      center: 'click_topBanner',
      mini: 'click_miniBanner',
    },
    GENRE: {
      new: 'player_genre_new_click',
      popular: 'player_genre_popular_click',
      snack: 'player_genre_snack_click',
      multiPlay: 'player_genre_multiPlay_click',
      comic: 'player_genre_comic_click',
      drama: 'player_genre_drama_click',
      historic: 'player_genre_history_click',
      educational: 'player_genre_education_click',
      detective: 'player_genre_detective_click',
      fantasy: 'player_genre_fantasy_click',
      'sci-fi': 'player_genre_sci-fi_click',
      horror: 'player_genre_horror_click',
    },
  },
  SCROLL_ACTION: {
    list: 'scroll_list',
  },
} as const;

const GOOGLE_CATEGORY = {
  EVENT: 'event',
} as const;
export { GOOGLE_CATEGORY, GOOGLE_ACTION };

import React from 'react';
import chevronDown from '../assets/images/down.svg';
import chevronUp from '../assets/images/up.svg';
import { atom, useAtom } from 'jotai';

const MoreInfo = () => {
  return (
    <>
      <p style={{ margin: '0 0 5px 0' }}>
        <span>
          <strong>사업자등록번호</strong> 524-88-00746
        </span>{' '}
        <></>
        <span>
          <strong>통신판매업신고번호</strong> 2018-서울성동-0760호
        </span>
      </p>
      <p style={{ margin: '0 0 5px 0' }}>
        <span>
          <strong>대표이사</strong> 이은영,송인혁
        </span>{' '}
      </p>
      <p>
        <span>
          <strong>주소</strong> 서울특별시 성동구 연무장13길 8, 메리히어 3층
        </span>
        <span>
          <strong>전화</strong> 070-8706-1010
        </span>
      </p>
    </>
  );
};

const isToggle = atom(false);
const FooterContainer = () => {
  const [toggleStatus, setToggleStatue] = useAtom(isToggle);
  return (
    <footer className="footer">
      <h4>
        주식회사 유니크굿컴퍼니{' '}
        <img
          src={toggleStatus ? chevronDown.toString() : chevronUp.toString()}
          onClick={() => setToggleStatue(!toggleStatus)}
          className="footer-icon"
        />
      </h4>
      {toggleStatus && <MoreInfo />}
    </footer>
  );
};

export default FooterContainer;

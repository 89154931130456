import React from 'react';
import TabModel from '@models/tab';
import { TabList } from '@components/TabList';

import MenuModel from '@models/menu';
import BannerModel from '@models/banner';
import SectionModel from '@models/section';

interface Menu {
  category: MenuModel;
  tabs: SectionModel[];
  centerBanners: BannerModel[];
  language: string;
}

const BodyContainer = ({ category, tabs, centerBanners, language }: Menu) => {
  const tabsMenu: TabModel[] = category.tabs ?? [];
  const isTabs = !!tabsMenu && !!tabsMenu.length;

  return isTabs ? <TabList tabsMenu={tabsMenu} category={category} tabs={tabs} centerBanners={centerBanners} language={language} /> : <></>;
};

export default BodyContainer;
